<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <button type="button" class="btn btn-primary" @click="addNewTarget">{{ is_edit ? 'Edit' : 'Add new' }} Target</button>
      <div class="row mt-5 align-items-center justify-content-between">
        <!-- <div class="col-md-3">
          <label for="">Select Center</label>
          <b-form-select
              id="doctor_type"
              v-model="filter.institute_id"
              :options="centers"
              text-field="name"
              value-field="id">
          </b-form-select>
        </div> -->
        <div class="col-md-3">
          <label for="monnth">Month</label>
          <b-form-select id="month" v-model="filter.start_date" :options="months"
                             ></b-form-select>
        </div>
        <div class="col-md-3">
          <label for="year">Year</label>
          <b-form-select
            id="year"
            v-model="filter.end_date"
            :options="years">
          </b-form-select>
        </div>
        <div class="col-md-3">
          <label for="">Select Team</label>
          <b-form-select
              id="doctor_type"
              value-field="id"
              text-field="name"
              v-model="filter.team_type"
              :options="teams">
          </b-form-select>
        </div>
        <div class="col-md-2 mt-7">
          <button class="btn btn-success" @click="getItems()" v-if="year && month">Filter</button>
          <button class="btn btn-success" disabled v-else>Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
                        <template #cell(month)="row">
                          <div>
                            {{ convertMonth(row.item.month) }}
                          </div>
                        </template>

                        <template #cell(team_type)="row">
                            {{ (row.item.team_type?teamConv[row.item.team_type]:'')}}
                        </template>
            <template #cell(action)="row">
              <div>
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <div>
                    <b-dropdown-item href="#" @click="editAction(row.item)">
                      <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="viewAction(row.item)">
                      <span class="pr-4"><i class="fas fa-eye"></i></span>view
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </div>
            </template>
          </b-table>

          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              @change="getItems"
              class="my-0"
              aria-controls="Targets"
          ></b-pagination>
        </div>
      </div>
      <b-modal
          id="addTarget"
          ref="modal"
          :title="`${this.form.id ? 'Update' : 'Add new'} target`"
          @hidden="resetModal"
          @ok="handleOk"
          size="lg">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <!-- <b-col cols="6" class="mb-3">
              <label for="doctor_type">Select Center</label>
              <b-form-select
                  id="doctor_type"
                  v-model="form.institute_id"
                  @change="changeTeamType()"
                  :options="centers"
                  text-field="name"
                  value-field="id"
                  :disabled="is_edit">
              </b-form-select>
            </b-col> -->
            <b-col cols="4" class="mb-3">
              <label for="doctor_type">Select Team</label>
              <b-form-select
                  @change="changeTeamType()"
                  v-model="form.team_type"
                  :options="teams"
                  text-field="name"
                  value-field="id"
                  :disabled="is_edit"
                  ></b-form-select>
            </b-col>
            <b-col cols="4">
              <label for="doctor_type">Month</label>
              <b-form-select id="doctor_type" v-model="form.month" :options="months"
                             :disabled="this.form.id ? true : false"></b-form-select>
            </b-col>
            <b-col cols="4">
              <label for="year">Year</label>
              <b-form-select
                  id="doctor_type"
                  v-model="form.year"
                  :options="years" :disabled="is_edit">
              </b-form-select>
            </b-col>
          </b-row>
          <br>
          <b-row v-if="form.users.length">
            <b-col cols="12">
              <table style="width: 100%;">
                <thead>
                  <th>User</th>
                  <th>Target Income (Tk.)</th>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in form.users" :key="index">
                    <td>
                      <b-form-input class="custom-height" v-model="user.fullname" disabled></b-form-input>
                    </td>
                    <td>
                      <b-form-input class="custom-height" v-model="user.target_income"></b-form-input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </form>
      </b-modal>
      <b-modal
          id="viewTarget"
          ref="modal"
          :title="`${this.form.id ? 'View' : 'Add new'} target`"
          hide-footer
          size="lg">
          <b-row class="mb-4">
            <!-- <b-col cols="6" class="mb-3">
              <h5 class="font-weight-light">Center:</h5>
              <h6 class="font-weight-bold">{{ form.institute_name  }}</h6>
            </b-col> -->
            <b-col cols="6">
              <h5 class="font-weight-light">Team Type:</h5>
              <h6 class="font-weight-bold">{{ form.team_type  }}</h6>
            </b-col>
            <b-col cols="6">
              <h5 class="font-weight-light">Periods</h5>
              <h6 class="font-weight-bold">{{ form.month }} - {{ form.year }}</h6>
            </b-col>
          </b-row>
          <b-row v-if="form.users.length">
            <b-col cols="12">
              <table style="width: 100%;">
                <thead>
                  <th>User</th>
                  <th>Target Income (Tk.)</th>
                </thead>
                <tbody class="pt-4">
                  <tr v-for="(user, index) in form.users" :key="index">
                    <td class="p-3 text-center">
                      {{ user.fullname }}
                    </td>
                    <td class="p-3 text-center">
                      {{ user.target_income }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
      </b-modal>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';

export default {
  name: "OrkoTeamTarget",
  data() {
    let all_months = [
      {
        id: 1,
        text: 'January',
        value: 'January'
      },
      {
        id: 2,
        text: 'February',
        value: 'February'
      },
      {
        id: 3,
        text: 'March',
        value: 'March'
      },
      {
        id: 4,
        text: 'April',
        value: 'April'
      },
      {
        id: 5,
        text: 'May',
        value: 'May'
      },
      {
        id: 6,
        text: 'June',
        value: 'June'
      },
      {
        id: 7,
        text: 'July',
        value: 'July'
      },
      {
        id: 8,
        text: 'August',
        value: 'August'
      },
      {
        id: 9,
        text: 'September',
        value: 'September'
      },
      {
        id: 10,
        text: 'October',
        value: 'October'
      },
      {
        id: 11,
        text: 'November',
        value: 'November'
      },
      {
        id: 12,
        text: 'December',
        value: 'December'
      },
    ];
    return {
      items: [],
      fields: [
        // {
        //   key: 'institute_name',
        //   label: 'Center',
        // },
        {
          key: 'team_type',
          label: 'Team Type',
        },
        {
          key: 'month',
          label: 'Month',
        },
        {
          key: 'year',
          label: 'Year',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      loading: false,
      form: {
        id: '',
        institute_id: '',
        team_type: '',
        month: '',
        year: '',
        users: [],
      },
      filter: {
        institute_id: '',
        team_type: '',
        month: '',
        year: '',
        target: '',
        start_date: null,
        end_date: null,
      },
      months: all_months,
      current_year: new Date,
      start_year: 2022,
      month: all_months[new Date().getMonth()].value,
      year: new Date().getFullYear(),
      years: [],
      centers: [],
      teamConv: { bd: 'BD', crm: 'CRM'},
      teams:[
        {id:'bd', name:'BD'},
        {id:'crm', name: 'CRM'}
      ],
      is_edit: false,
      total_count: 0,
      currentPage: 1,
      perPage: 10,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "OCC Team Target", route: "/occ/team/target"},
    ]);
  },
  created() {
    this.getItems();
    // this.centerList();
    this.setYears(15);
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
  },

  methods: {
    getItems(value) {
      this.loading = true;
      if (value) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/report/orkocenter/team/targets?api_token=${localStorage.getItem('api_token')}
      ${this.filter.institute_id ? '&institute_id=' + this.filter.institute_id : ''}${this.filter.team_type ? '&team_type=' + this.filter.team_type : ''}
      ${this.filter.start_date ? '&month=' + this.filter.start_date : ''}${this.filter.end_date ? '&year=' + this.filter.end_date : ''}&limit=${this.perPage}${correctValue ? '&offset=' + correctValue : ''}`).then(res => {
        this.items = res.data.data;
        this.total_count = res.data.total_count;
        this.loading = false;
      })
    },

    changeTeamType(){
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/user/sales/users?role=${this.form.team_type}&api_token=${localStorage.getItem('api_token')}`)
          .then((res) => {
            this.form.users = res.data.data;
          });
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.loading = false
              this.centers = response.data.data
            }
          })
    },
    addNewTarget() {
      this.$bvModal.show('addTarget')
    },

    convertMonth(month) {
      const mn = this.months.find(ite => ite.id == month);
      return mn.text;
    },

    editAction(item) {
      this.$bvModal.show('addTarget')
      this.is_edit = true;
      this.form.id = item.id;
      // this.form.institute_id = item.institute_id;
      this.form.year = item.year;
      this.form.team_type = item.team_type;
      const mn = this.months.find(ite => ite.id == parseInt(item.month));
      this.form.month = mn.text;
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/report/orkocenter/team/target/items?api_token=${localStorage.getItem('api_token')}&month=${item.month}&year=${item.year}&team_type=${item.team_type}`)
      .then(res => {
          this.form.users = res.data.data
          this.loading = false;
      })
    },
    viewAction(item) {
      this.$bvModal.show('viewTarget')
      this.is_edit = false;
      this.form.id = item.id;
      // this.form.institute_id = item.institute_id;
      // this.form.institute_name = item.institute_name
      this.form.year = item.year;
      this.form.team_type = item.team_type;
      const mn = this.months.find(ite => ite.id == parseInt(item.month));
      this.form.month = mn.text;
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/report/orkocenter/team/target/items?api_token=${localStorage.getItem('api_token')}&month=${item.month}&year=${item.year}&team_type=${item.team_type}`)
      .then(res => {
        
          this.form.users = res.data.data
        // console.log('dsd',this.prodouct_types[item.service_type], 'name', this.form.services['name']);
          this.loading = false;
      })
    },

    setYears(num) {
      for (let i = 0; i < num; i++) {
        this.years.push(this.start_year + i);
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.form = {users:[]};
      this.is_edit = false;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      this.form.users = this.form.users.map(user => {
        user.user_id = user.user_id?user.user_id:user.id;
        return user;
      })
      this.$nextTick(() => {
        let url = '';
        if (this.form.id) {
          url = `${process.env.VUE_APP_API_URL}/api/v2/report/orkocenter/team/targets/update?api_token=${localStorage.getItem('api_token')}&_method=put`;
        } else {
          url = `${process.env.VUE_APP_API_URL}/api/v2/report/orkocenter/team/targets?api_token=${localStorage.getItem('api_token')}`;
        }
        axios.post(url, this.form).then(res => {
          if (res.data.status_code == 200) {
            this.getItems();
            this.$bvModal.hide('addTarget')
            this.$snotify.success('Successfully saved');
          } else {
            this.$snotify.error(res.data.message);
          }
        })
      })
    },
    clear() {
      this.filter = {};
      this.getItems();
    }
  }

}
</script>

<style scoped>
table  th {
    color: #002147;
    border-bottom: 1px solid #a0a0a1;
    padding-bottom: 10px;
    text-align: center;
    font-size: 15px;
}
table td {
  padding: 5px;
}
.custom-height {
  height: 30px !important;
}
</style>
